<template>
    <div class="text-center">
            <v-dialog v-model="show" persistent>
                <v-card>
                    <v-card-text>
                        Already a member Exist in this address, do you want to be a group ?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1"  @click="this.$emit('no')">
                            No
                        </v-btn>
                        <v-btn color="green darken-1" text @click="this.$emit('yes')">
                            Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'dialogVue',
    data() {
        return {
            dialog: false,
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    }
}
</script>