import axios from 'axios'
export default {
    namespaced: true,
    state: {
        postCodeList: [],
        addressList: [],
        boroughFromAddress: '',
    },
    mutations: {
        SET_POST_CODE_LIST( state, data){
            state.postCodeList = data
        },
        SET_ADDRESS_LSIT( state, data){
            state.addressList = data
        },
        SET_BOROUGH( state, data){
            state.boroughFromAddress = data
        },
    },
    actions: {
         /* eslint-disable */
        async GET_POST_CODE_LIST({ commit, state }, query) {
             /* eslint-disable */
            // const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'post',
                // url: `${process.env.VUE_APP_BASE_URL}list_of_extras`,
                // https://api.getaddress.io/find/SW10%200LQ?api-key=aKLxAfanHk6gdCqIOD2Tlg36848&expand=true
                url: `https://api.getAddress.io/typeahead/${query}?api-key=${process.env.VUE_APP_GET_ADDRESS_KEY}`,
                // url: `https://api.getAddress.io/typeahead/PE?api-key=aKLxAfanHk6gdCqIOD2Tlg36848`,
                data:{
                    search:["postcode"]
                }
            }

            const data = await axios(options)
            // console.log(data)
            commit('SET_POST_CODE_LIST', data.data)
            
        },
        async GET_ADDRESS_LSIT({ commit, state }, query){
            const data = await axios({
                method: 'get',
                // url: `https://api.getaddress.io/find/${query}?api-key${process.env.VUE_APP_GET_ADDRESS_KEY}&expand=true`
                url: `https://api.getaddress.io/find/${query}?api-key=${process.env.VUE_APP_GET_ADDRESS_KEY}&expand=true`
            })
            // console.log(data.data.addresses)
            commit('SET_ADDRESS_LSIT', data.data.addresses);
            commit('SET_BOROUGH', data.data.addresses[0].district);
            // console.log(state.boroughFromAddress);

        }
        
       
    },
    getters: {
        getAddresByline_1:(state) => (line) =>{
            return state.addressList.filter( list => list.line_1 == line )
        } 
        // const state = await this.$store.getters["states/getStateByCountryId"](
        //     results.id
        //   );
        
    }

}